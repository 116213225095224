(function() {
  if($L) {
    $L.prototype.cruxMention = function(featureObj) {
      //here 'this' points to $L(selector);
      var mentionInputBox = this[0];
      var features = featureObj ? featureObj : {};

      var textarea;
      if(mentionInputBox.nodeName === "TEXTAREA") { //NO I18n
        textarea = mentionInputBox;
      } else if(mentionInputBox.nodeName === "LYTE-INPUT") { //NO I18n
        textarea = mentionInputBox.querySelector("textarea"); //NO I18N
      }

      //removes all the event bindings to the textarea
      if(features.destroy === true) {
        //removes the input event bound to textarea for formattedText content in the plugin.
          textarea.removeEventListener('input', window.cruxMentionUtil.getTextContent); //NO I18n

        if(textarea && textarea._mIData) {
          var cruxMentionElem = textarea._mIData.lyteMIList.querySelector("#cruxMention"); //NO I18n

          //removes all events bound by mentionsInput plugin.
          if(cruxMentionElem) {
            cruxMentionElem.remove();
          } else {
            this.mentionsInput("destroy"); //NO I18N
          }
        }

        if(window.cruxMentionUtil) {
          delete window.cruxMentionUtil;
        }
      } else {
        //default values for features provided by cruxMention plugin
        var defaultFeatures = {
          textboxSelector: "", //NO i18n
          searchModules: ["users", "groups", "roles"], //NO I18n
          minChars: 2,
          triggerChar: "@", //NO i18n
          componentName: "crux-mentions-body", //NO i18n
          cxPropCacheQuery: true,
          existingMentions: [],
          queryParam: {},
          productName: "crm", //NO i18n
          requestInterval: 0,
          maxMentionNameLength: 101,
          preventParentScroll : false
        }

        // initializing data attributes.
        var textboxSelector = features.textboxSelector ? features.textboxSelector : defaultFeatures.textboxSelector;
        var searchModules = features.searchModules ? features.searchModules : defaultFeatures.searchModules;
        var minChars = features.minChars > 0 ? features.minChars : defaultFeatures.minChars;
        var triggerChar = features.triggerChar ? features.triggerChar : defaultFeatures.triggerChar;
        var componentName = features.componentName ? features.componentName : defaultFeatures.componentName;
        var cxPropCacheQuery = features.cxPropCacheQuery ? features.cxPropCacheQuery : defaultFeatures.cxPropCacheQuery;
        var existingMentions = features.existingMentions ? features.existingMentions : defaultFeatures.existingMentions;
        var queryParam = features.queryParam ? features.queryParam : defaultFeatures.queryParam;
        var productName = features.productName ? features.productName : defaultFeatures.productName;
        var requestInterval = features.requestInterval ? features.requestInterval : defaultFeatures.requestInterval;
        var maxMentionNameLength = features.maxMentionNameLength ? features.maxMentionNameLength : defaultFeatures.maxMentionNameLength;
        var preventParentScroll = features.preventParentScroll ? features.preventParentScroll : defaultFeatures.preventParentScroll;
        var dropdownClass = features.dropdownClass ? features.dropdownClass : "";

        //adds start and end position to mentions as position property from mention is not available on editing the mentions.
        var mentionsLen = existingMentions.length;
        if(mentionsLen) {
          var noteContent = textarea.value;
          var startPos, endPos, mentionName;

          for(var i = 0; i < mentionsLen; i++) {
            // mentionName = existingMentions[i].$setype === "Users" ? existingMentions[i].full_name : existingMentions[i].name; //NO I18N
            if(existingMentions[i].$setype === "Users"){
                existingMentions[i].name = existingMentions[i].full_name;
            }
            mentionName = existingMentions[i].name;
            startPos  = noteContent.indexOf("@" + mentionName); //NO I18N
            endPos = startPos + mentionName.length + 1;
            existingMentions[i].position = {start: startPos, end: endPos};
          }
        }
        //crux mentions utility methods
        // if(!window.cruxMentionUtil) {
          window.cruxMentionUtil = {};
          cruxMentionUtil.triggerChar = triggerChar;
          cruxMentionUtil.mentionedMentions = existingMentions;
          cruxMentionUtil.productName = productName;

          window.cruxMentionUtil.getTextContent = function (event) {
            var textarea = event.target;
            // var bindingClass;
            // var lyetInputDivElem = textarea.parentElement.parentElement;
            // if(lyetInputDivElem.classList.contains("lyteField")) { //NO i18n
            //   bindingClass = lyetInputDivElem.parentElement.getAttribute("id"); //NO i18n
            // } else {
            //   bindingClass = textarea.getAttribute("id"); //NO i18n
            // }
            // var cruxMentionElem = bindingClass ? document.querySelector("crux-mentions-body." + bindingClass) : null; //NO I18n

            // if(cruxMentionElem) {
              // var cruxMentionComp = cruxMentionElem.component;
              // var mentionedMentions = cruxMentionComp.getData("mentionedMentions"); //NO i18n
              var mentionedMentions = existingMentions;
              // var currentMentions = textarea.value.split(cruxMentionComp.getData("triggerChar")); //NO i18n
              var triggerChar = cruxMentionUtil.triggerChar;
              var currentMessage = textarea.value;
              var currentMessageLen = currentMessage.length;
              var triggerCharIndices = [];

              for(var i = 0; i < currentMessageLen; i++) {
                if(currentMessage[i] === triggerChar) {
                  if(currentMessage[i - 1] && currentMessage[i - 1].match(/[a-z,0-9]/i) && currentMessage[i + 1] && currentMessage[i + 1].match(/[a-z,0-9]/i)) {
                    continue;
                  } else {
                    triggerCharIndices.push(i);
                  }
                }
              }

              var triggerCharIndicesLen = triggerCharIndices.length
              var currentMentions = [];

              for(var i = 0; i < triggerCharIndicesLen; i++) {
                if(triggerCharIndices[i + 1] !== undefined) {
                  currentMentions[i] = currentMessage.substring(triggerCharIndices[i] + 1, triggerCharIndices[i + 1]);
                } else {
                  currentMentions[i] = currentMessage.substring(triggerCharIndices[i] + 1);
                }
              }

              var currentMentionsLen = currentMentions.length;
              mentionedMentions = mentionedMentions.filter(function(mention) {
                var found = false;
                if(mention.$setype === "Users") { //NO i18n
                  for(var i = 0; i < currentMentionsLen; i++) {
                    if(currentMentions[i].indexOf(mention.full_name) !== -1) {
                      mention.name = mention.full_name;
                      found = true;
                      break;
                    }
                  }
                } else if(mention.$setype === "Groups") { //NO i18n
                  for(var i = 0; i < currentMentionsLen; i++) {
                    if(currentMentions[i].indexOf(mention.name) !== -1) {
                      found = true;
                      break;
                    }
                  }
                } else if(mention.$setype === "Roles") { //NO i18n
                  for(var i = 0; i < currentMentionsLen; i++) {
                    if(currentMentions[i].indexOf(mention.name) !== -1) {
                      found = true;
                      break;
                    }
                  }
                }
                return found;
              });
              // cruxMentionComp._textarea._mIData.lyteMICollection = mentionedMentions;
              // cruxMentionComp.setData("mentionedMentions", mentionedMentions); //NO i18n
              cruxMentionUtil.mentionedMentions = mentionedMentions;
              existingMentions = mentionedMentions;
              //callback for formatted text in the textarea, data used to make n/w call while saving the note.
              if(features.cxPropContent && features.cxPropContent.constructor === Function) {
                if(textarea) {
                  textarea._mIData.lyteMICollection = cruxMentionUtil.mentionedMentions;
                }
                var formattedText = cruxMentionUtil.formatMentions(textarea.value, mentionedMentions);
                features.cxPropContent(formattedText);
              }
            // }
            // else {
            //   if(features.cxPropContent && features.cxPropContent.constructor === Function) {
            //     if(textarea) {
            //       textarea._mIData.lyteMICollection = cruxMentionUtil.mentionedMentions;
            //     }
            //     // features.cxPropContent(event.target.value);
            //     var formattedText = cruxMentionUtil.formatMentions(textarea.value, cruxMentionUtil.mentionedMentions);
            //     features.cxPropContent(formattedText);
            //   }
            // }
          }
        // }

        cruxMentionUtil.formatMentions = function(text, mentionedMentions) {
      		var productName = cruxMentionUtil.productName;
          var triggerChar = cruxMentionUtil.triggerChar;

      		mentionedMentions.forEach(function(mention) {
      			if(mention.$setype === "Users") { //NO i18n
      				text = text.replace(triggerChar + mention.full_name, productName + "[user#" + mention.id + "#" + mention.zuid + "]" + productName); //NO i18n
      			} else if(mention.$setype === "Roles") { //NO i18n
      				text = text.replace(triggerChar + mention.name, productName + "[role#" + mention.id + "#" + mention.id + "]" + productName); //NO i18n
      			} else if(mention.$setype === "Groups") { //NO i18n
      				text = text.replace(triggerChar + mention.name, productName + "[group#" + mention.id + "#" + mention.id + "]" + productName); //NO i18n
      			}
      		});

      		return text;
        }

        if(mentionInputBox.nodeName === "TEXTAREA") { //NO I18n
          mentionInputBox.addEventListener('input', window.cruxMentionUtil.getTextContent); //NO I18n
        } else if(mentionInputBox.nodeName === "LYTE-INPUT") { //NO I18n
          mentionInputBox.querySelector("textarea").addEventListener('input', window.cruxMentionUtil.getTextContent); //NO I18n
        }

        // mentionsInput starts here
        var self = this;
        this.mentionsInput({
          'minChars': minChars, //NO i18n
          'triggerChar': triggerChar, //NO i18n
          'dropbody': false, //NO i18n
          'interval': requestInterval, //NO I18n
          'preventParentScroll' : preventParentScroll , //NO I18n
          'onHide':function(list,textarea){ //NO i18n
            list.classList.remove('lyteMIDisplayBlock'); //NO i18n
            list.classList.remove('cx_cruxMentionDropdown'); //NO i18n
          },
          'onDataRequest': function(query) { //NO i18n
            if(query.length > maxMentionNameLength || query.split("  ").length > 1) {
              return [];
            }

            if(features.customRequest && features.customRequest.constructor === Function) {
              return features.customRequest(query);
            } else {
              var qp = {"searchword": query, "meta_resources": searchModules.join(), "display_field": true, page: 1, per_page: 200}; //NO I18N

              for(var key in queryParam) {
                qp[key] = queryParam[key];
              }

              return store.findAll("global-search", qp, cxPropCacheQuery); //NO i18n
            }
          },
          'displayMentions': function(mentions, mentionText) { //NO i18n
            var mentionLen = mentions ? mentions.length : 0 , textarea;
            if(mentionText === self.mIManager.currentDataQuery && mentions && mentionLen > 0) {
              //categorize users, roles and groups
              var userMentions = [];
              var roleMentions = [];
              var groupMentions = [];

              for(var i = 0; i < mentionLen; i++) {
                if(mentions[i].$setype === "Users") { //NO I18n
                  userMentions.push(mentions[i]);
                } else if(mentions[i].$setype === "Roles") { //NO I18n
                  roleMentions.push(mentions[i]);
                } else if(mentions[i].$setype === "Groups") { //NO I18n
                  groupMentions.push(mentions[i]);
                }
              }

              mentions = userMentions.concat(roleMentions).concat(groupMentions);
              //binds each component to its calling textbox.
              // var bindingClass = textboxSelector.startsWith("#") || textboxSelector.startsWith(".") ? textboxSelector.slice(1) : textboxSelector;
              // var cruxMentionComp = document.querySelector("#cruxMention." + bindingClass); //NO i18n
              // var elem = document.querySelector(textboxSelector);
              if(mentionInputBox.nodeName === "TEXTAREA") { //NO I18n
                textarea = mentionInputBox;
              } else if(mentionInputBox.nodeName === "LYTE-INPUT") { //NO I18n
                textarea = mentionInputBox.querySelector("textarea"); //NO I18N
              }
              var dropArea = textarea._mIData.lyteMIList;
              var cruxMentionComp = $L(componentName , dropArea)[0];
              if(mentionInputBox && !cruxMentionComp) {
                textarea._mIData.lyteMIList.classList.add("cx_cruxMentionDropdown"); //NO i18n
                // if(elem.nodeName === "TEXTAREA") {
                //   elem._mIData.lyteMIList.classList.add(bindingClass);
                // } else if(elem.nodeName === "LYTE-INPUT") {
                //   elem.querySelector("textarea")._mIData.lyteMIList.classList.add(bindingClass); //NO i18n
                // }

                // filters out existing mentions in the textarea when editing a note.
                var existingMentionsLen = existingMentions.length;
                if(existingMentionsLen > 0) {
                  var unmentionedMentions = mentions.filter(function(mention) {
                    var mentionFound = false;

                    for(var i = 0; i < existingMentionsLen; i++) {
                      if(existingMentions[i].full_name && existingMentions[i].full_name === mention.full_name) {
                        mentionFound = true;
                        break;
                      } else if(existingMentions[i].name && existingMentions[i].name === mention.name) {
                        mentionFound = true;
                        break;
                      }
                    }

                    //return false to prevent from being added to unmentionedMentions and vice versa
                    return !mentionFound;
                  });
                }

                cruxMentionComp = Lyte.Component.render(componentName, {'lyteDom': self, 'mentions': existingMentionsLen > 0 ? unmentionedMentions : mentions, 'textboxSelector': textboxSelector, 'triggerChar': triggerChar, 'mentionText': mentionText, 'features': features , 'dropdownClass' : dropdownClass}, ".cx_cruxMentionDropdown"); //NO i18n

                // sets already mentioned mentions while editing the note.
                cruxMentionComp.setData("mentionedMentions", existingMentions); //NO I18N
                cruxMentionUtil.mentionedMentions = mentionedMentions;
                // $L(elem.parentElement.querySelector("."+bindingClass)).addClass('lyteMIDisplayBlock'); //NO I18n
                cruxMentionComp.setAttribute("id", "cruxMention"); //NO i18n
                // cruxMentionComp.setAttribute("class", bindingClass); //NO i18n
              } else if(mentionInputBox){
                cruxMentionComp.setData("mentionedMentions", existingMentions); //NO I18N
                var mentionedMentions = cruxMentionComp.getData("mentionedMentions"); //NO i18n
                var mentionedMentionsLen = mentionedMentions.length;

                //filters out already mentioned mentions
                var unmentionedMentions = mentions.filter(function(mention) {
                  var mentionFound = false;

                  for(var i = 0; i < mentionedMentionsLen; i++) {
                    if(mentionedMentions[i].full_name && mentionedMentions[i].full_name === mention.full_name) {
                      mentionFound = true;
                      break;
                    } else if(mentionedMentions[i].name && mentionedMentions[i].name === mention.name) {
                      mentionFound = true;
                      break;
                    }
                  }

                  //return false to prevent from being added to unmentionedMentions and vice versa
                  return !mentionFound;
                });

                if(unmentionedMentions.length > 0) {
                  cruxMentionComp.setData({
                    'mentions': unmentionedMentions,  //NO i18n
                    'textboxSelector': textboxSelector,  //NO i18n
                    'triggerChar': triggerChar,  //NO i18n
                    'mentionText': mentionText //NO i18n
                  });
                  cruxMentionComp.querySelector(".mentionBody").setAttribute("style", "display: block"); //NO i18n
                  // $L(elem.parentElement.querySelector("."+bindingClass)).addClass('lyteMIDisplayBlock'); //NO I18n
                  dropArea.classList.add('lyteMIDisplayBlock'); //NO I18n
                } else {
                  self.mIManager.inputBuffer = [];
                  return false;
                }
              }
            } else {
              return false;
            }
          }
        });
        // mentionsInput ends here
      }
    }
  }
})();
